@font-face {
  font-family: 'RubikMedium';
  src: url('/src/components/Lib/assets/fonts/rubik-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RubikRegular';
  src: url('/src/components/Lib/assets/fonts/rubik-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}